"use client";

import { useEffect, useId } from "react";

if (typeof window !== "undefined") {
  import("@rbup/rbup-video-cartoons-webcomponent");
}

export const RbVideo = ({
  id,
  isAutoPlaying,
  isLooping,
}: {
  id: string | null;
  isAutoPlaying: boolean;
  isLooping: boolean;
}) => {
  const elementId = useId();

  useEffect(() => {
    customElements.whenDefined("rbup-video-cartoons").then(async () => {
      if (!isLooping) {
        return;
      }

      /**
       * This player has been implemented according to the Red Bull Platforms
       * documentation. You can read more about that here:
       * @see {@link https://platforms.redbull.com/develop/custom-development/video-player-integration/rbcom-video-integration}
       */
      // @ts-ignore: init function injected by rbup script
      const api = await window.initRbupVideoCartoons(CSS.escape(elementId), {
        "asset-id": id,
      });

      api.addEventListener(({ type }: { type: string }) => {
        if (type === "playbackFinished") {
          api.seek(0);
          api.play();
        }
      });
    });
  }, [elementId, id, isLooping]);

  return <rbup-video-cartoons id={elementId} autoplay={isAutoPlaying} />;
};
